const Constant = {
  //Prod--> SITE_KEY: "6LeLkbgpAAAAAKk3cyYkgPNkdBp-tZH-2fXAkCMO",
  //UAT--> SITE_KEY: "6LeE8OUpAAAAALkAK2hYneTTTCJUVznR0smfYZFS",
  SITE_KEY: "6LeLkbgpAAAAAKk3cyYkgPNkdBp-tZH-2fXAkCMO",
  PERMIT_CHECKER_RESULT_TITLE: "Enquiry Tool",
  PERMIT_CHECKER_RESULT_TITLE_MORE_INFORMATION: "Enquiry Tool",
  APP_NAME: "SkySAFE",
  FOOTER_LAST_UPDATED_DATE_TEXT: "Last updated on 26 Mar 2025",
  //process.env.REACT_APP_CAPTCHA_SITE_KEY,
  userSalutation: [
    {
      "label": "Dr",
      "value": "Dr",
      "isdisabled": true,
    },
    {
      "label": "Mdm",
      "value": "Mdm",
      "isdisabled": true,
    },
    {
      "label": "Mr",
      "value": "Mr",
      "isdisabled": true,
    },
    {
      "label": "Mrs",
      "value": "Mrs",
      "isdisabled": true,
    }],
  card: [
    {
      id: 1,
      img: "/images/aero_modeling.jpg",
      title: "Clearance for Aerial Photography/Videography",
      text: "Within the busy airspace of Singapore, scheduling aerial photography flights can be challenging. Safety is a key concern.To conduct any aerial photography / videography flights.....",
      header: "Clearance for Aerial Photography/Videography ",
    },
    {
      id: 2,
      img: "/images/license.jpg",
      title: "Clearance for Erection of Cranes and Tall Construction Machinery",
      text: "Development of buildings, use of tall construction machines such as cranes can potentially affect safety of air navigation either due to the close proximity between the structure.....",
      header: "PERMIT FOR CRANES AND TALL CONSTRUCTION MACHINERY",
    },
    {
      id: 3,
      img: "/images/license.jpg",
      title: "Clearance for Ship Crossing",
      text: "The conduct of ship crossing can potentially affect safety of air navigation either due to the close proximity between the vessel and an overflying aircraft, or the vessel may cause an obstruction.....",
      header: "Clearance for Ship Crossing",
    },
    {
      id: 4,
      img: "/images/sky_lantern.jpg",
      title: "Permit for Aerial Activities",
      text: "Some aerial activities can potentially pose a hazard to air navigation, either due to the close proximity between an object in the air and an overflying aircraft, or an object may cause an.....",
      header: "Permit for Aerial Activities",
    },
  ],
  applicationTypes: [
    {
      id: 1,
      value: 1,
      label: "Aerial Photography/Videography",
    },
    {
      id: 2,
      value: 2,
      label: "Erection of Cranes and Tall Construction Machinery",
    },
    {
      id: 3,
      value: 3,
      label: "Ship Crossing",
    },
    {
      id: 4,
      value: 4,
      label: "Aerial Activities",
    },
  ],
  COMPANY_ROLE: [
    {
      "label": "Developer",
      "value": "DEVELOPER",
      "isdisabled": true,
    },
    {
      "label": "Main Contractor",
      "value": "MAIN_CONTRACTOR",
      "isdisabled": true,
    },
    {
      "label": "Subcontractor",
      "value": "SUBCONTRACTOR",
      "isdisabled": true,
    }],
    ROLE: "Role",
  Permit: ['Aerial Activities','Aerial Photography/Videography', 'Cranes and Tall Construction Machinery', 'Ship Crossing'],
  caasPermit: {
    img2: "/images/aerialphotography.jpg",
    img3: "/images/crane.jpg",
    img4: "/images/shipcrossing.jpg",
    img1: "/images/heliumbaloons.jpg"
  },
  activityTypes: [
    {
      label: "Release of Balloons",
      id: "BRELEASE",
      value: "BRELEASE",
    },
    {
      label: "Hoisting of Captive Balloon/Blimp",
      id: "BHOISTING",
      value: "BHOISTING",
    },
    {
      label: "Kite Flying/Parasailing",
      id: "KITE",
      value: "KITE",
    },
    {
      label: "Lasers/Other Lights Display",
      id: "LASERS",
      value: "LASERS",
    },
    {
      label: "Release of Sky Lanterns",
      id: "SLRELEASE",
      value: "SLRELEASE",

    },
    {
      label: "Fireworks/Pyrotechnics Display",
      id: "FIREWORKS",
      value: "FIREWORKS",
    },
  ],
  activityTypesWithId: {
    BRELEASE: {
      label: "Release of Balloons",
      id: "BRELEASE",
      value: "BRELEASE",
    },
    BHOISTING: {
      label: "Hoisting of Captive Balloon/Blimp",
      id: "BHOISTING",
      value: "BHOISTING",
    },
    KITE: {
      label: "Kite Flying/Parasailing",
      id: "KITE",
      value: "KITE",
    },
    LASERS: {
      label: "Lasers/Other Lights Display",
      id: "LASERS",
      value: "LASERS",
    },
    SLRELEASE: {
      label: "Release of Sky Lanterns",
      id: "SLRELEASE",
      value: "SLRELEASE",

    },
    FIREWORKS: {
      label: "Fireworks/Pyrotechnics Display",
      id: "FIREWORKS",
      value: "FIREWORKS",
    },
  },
  INVALID_RECAPTCHA: "INVALID RECAPTCHA",
  INVALID_CSRF_TOKEN: "INVALID CSRF TOKEN",
  UNAUTHORIZED: "401 UNAUTHORIZED",
  VALIDATION_ERROR: "VALIDATION_ERROR",
  TOO_MANY_REQUESTS: "TOO_MANY_REQUESTS",
  EMAIL_REGEX: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
  FIND_FOR_WAF_RESPONSE: 'Microsoft-Azure-Application-Gateway',
  error: {
    header: "Error!!",
    //body: "Service is not available. Please contact system administrator",
    body: "Oops! Something went wrong. Please try again, and if this persists, contact system administrator",
    specialCharacter: "An unexpected error has occurred. Our system thinks you might be a robot and has blocked the request.\
     This issue may also be attributed to the excessive use of special characters in your inputs above, such as ampersand (&), \
     single quote ('), double quote (\"), slash (/), etc. Please remove some of these special characters and attempt to submit again.\
      If the problem persists, please contact us at CAAS_ATS_ANSP@caas.gov.sg",    
    no_record_body: "Record(s) not found.",
    message: {
      RECORD_NOT_FOUND: "Record not found",
    },
    unauthorized: "Session timed out. Please sign in again to continue.",
    invalidCsrfToken: "Security check failed. Please try again. If this issue persists, contact our support team.",
    invalidRecaptcha: "Security check failed. If you are using a VPN, please disconnect and try again. Alternatively, attempt access from a different device. \
    If the issue persists, contact our support team.",
    onemapPostalApiIssue: "Unable to retrieve data.",
    onemapPostalApiNotFound: "Address not found.",
    tooManyRequests: "Too may requests.", 
    webAppFireWallBlocked: "Request has been blocked for security reasons. \
    Please review the on-screen error message, follow the instructions provided, and try again.",
  },
  loginImg: "/images/login-with-Singpass.svg",
  MAX_FILE_COUNT: 5,
  MAX_USER_FILE_COUNT: 2,
  YOUR_APPLICATION: "Application Reference: ",
  FILE_COUNT_EXCEEDED: "Total Number of Files cannot be more then 5.",
  FILE_SIZE_MESSAGE: "File size should not be more than 2MB.",
  FILE_UPLOAD: "File Upload",
  FILE_UPLOAD_FORMAT: "File format should be PDF, CSV and Image only.",
  FILE_UPLOAD_ERROR_MESSAGE: [
    "The file(s) could not be uploaded due to one of the following reasons. Please check and retry.",
    "The format, extension, or content of one or more files is not permitted.",
    "The size of each file must not exceed 2MB."],
  FILE_REMOVE_ERROR_MESSAGE:
    "The file(s) removal was unsuccessful. Please try again.",
  FILE_DOWNLOAD_ERROR_MESSAGE:
    "The file download was unsuccessful. Please try again.",
  FILE_REMOVE_EMPTY_ERROR_MESSAGE: "Empty file cannot be removed.",
  POSTAL_CODE_ERR_MSG: "Postal Code cannot be less than 5 digits",
  CONTACT_NUMBER: "Contact Number",
  TEXT_AREA_LABEL: "Leave a comment here",
  SAFETY_DETAILS: "Safety Details",
  PRIMARY_SAFTEY_LABEL: "Name of Primary Safety Personnel",
  APPLICATION_DETAILS: "Application Details",
  APPLICATION_NUMBER: "Application Reference",
  STATUS: "Status",
  CONTACT_NUMERIC_ONLY: "Contact Number should be a number",
  SECONDARY_SAFETY_LABEL: "Name of Secondary Safety Personnel",
  SAFETY_MEASURES: "Please List the Safety Measures Employed",
  ADDITIONAL_DOCUMENT: "Any Supporting Documents",
  UPLOAD_SUPPORT_DOC_INFO: "Upload supporting documents (if any). \
  Acceptable file formats: \"PDF\", \"PNG\", \"JPG\", \"JPEG\"",
  BTN_UPLOAD: "Upload",
  BTN_CANCEL: "Cancel",
  BTN_DELETE: "Delete",
  BTN_GO_BACK: "Go Back",
  BTN_PENDING_PAYMENT: "Proceed to Payment",
  PREVIOUS: "Previous",
  NEXT: "Next",
  BTN_HOME: "Home",
  NRIC_PASSPORT: "Passport Number/NRIC",
  PASSPORT_NUMBER: "Passport Number",
  NRIC_FIN_NO: "NRIC/FIN Number",
  NRIC_FIN: "NRIC/FIN",
  FULL_NAME: "Full Name",
  COMPANY_NAME: "Company Name",
  EMAIL_ADDRESS: "Email Address",
  ACTION: "Action",
  ADD_ROW: "Add Row",
  OPERATOR_DETAILS: "Operator Details",
  OPERATOR_NAME_COMPANY: "Name of Operator’s Company",
  OPERATOR_POSTAL_CODE: "Operator Company Postal Code",
  FETCH_ADDRESS: "Retrieve Address",
  BLOCK_NO: "Block  / House No",
  STREET_NAME: "Street Name",
  LEVEL: "Level",
  UNIT_NUMBER: "Unit Number",
  NUMBER_OF_UNITS: "Specifications of Lasers / Lights used- No. of units",
  BUILDING_NAME: "Building Name",
  TYPE_OF_ACTIVITY: "Type of Activity",
  INSTRUCTIONS: "Instructions",
  INSTRUCTIONS_BODY: "",
  ADD_LOCATION: "Add Location",
  EDIT_LOCATION: "Edit Location",
  LOCATION_EDIT_NOT_ALLOWED:"Location Edit Not Allowed",
  LOCATION_MAP: "Area Selection On Map",
  VENUE_DETAILS: "Venue Details",
  PURPOSE: "Purpose",
  REMARKS: "Remarks",
  FIREWORKS_APPLICATION: "Your Application",
  FIREWORKS_DURATION:
    "Estimated Duration of Fireworks / Pyrotechnics Discharge (min)",
  ESTIMATED_DURATION_PLACEHOLDER: "Estimation Duration",
  ESTIMATED_DURATION_DIGIT_ONLY: "Estimated Duration should be a number without decimal places",
  FIREWORKS_NUMBER_OF_UNITS:
    "Number of Units of Fireworks / Pyrotechnics to be discharged",
  NUMBER_UNITS_DIGITS_ONLY: "Number of Units should be a number without decimal places",
  LASER_TYPE: "Specifications of Lasers / Lights used- Type",
  MAX_POWER: "Specifications of Lasers / Lights used- Maximum Power Output (W)",
  MAX_POWER_DIGIT_ONLY: "Maximum Power Output should be a number without decimal places",
  DANGER_RADIUS: "Danger Radius (m)",
  DANGER_RADIUS_DIGIT_ONLY: "Danger Radius metres should be a number without decimal places",
  LEVELNO_DIGIT_ONLY: "Level should be a number without decimal places",
  CAPTIVE_BALLOON_LABEL: "Number of Balloon(s)/Blimp(s) to be hoisted",
  MAX_HEIGHT_CAPTIVE_LABEL:
    "Maximum Height of Hoisted Balloon(s)/Blimp(s) Feet Above Ground level",
    MAX_HEIGHT_CAPTIVE_LABEL_MANDATORY:
    "Maximum Height of Hoisted Balloon(s)/Blimp(s) is mandatory",
   MAX_HEIGHT_CAPTIVE_DIGIT_ONLY:
    "Maximum Height should be a number without decimal places",
  MIN_CAPTIVE_BALLOON: "Number of Balloons/Blimps should be a number without decimal places",
  MAX_HEIGHT_CAPTIVE_BALLOON:
    "Maximum Height of Hoisted Balloon(s)/Blimp(s) Feet Above Ground level",
  MAX_DIM_HOISTED_BALLOON: "Maximum Dimension of Hoisted Balloon(s)/Blimp(s) (m)",
  MAX_DIM_HOISTED_BALLOON_DIGIT_ONLY: "Maximum Dimension should be a number without decimal places",
  DIMENSION_WEIGHT: "Weight (kg)",
  DIMENSION_WEIGHT_DIGIT_ONLY:
    "Maximum Weight should be a number without decimal places",
  AERIAL_FIELD_DECIMAL:
    " should a number with up to 4 digits and 1 decimal place.",
    /* DECIMAL REGEX will allow 4 digit whole number (eg., 9999) or 4 digit + 1 decimal point (ec: 9999.9) */
  NUM_1DECIMAL_REGEX: /^([0-9]{1,4}[.][0-9]{1}|([0-9]{1,4}))$/,
  NUM_REGEX: /^[0-9]+$/,
  LAT_LONG_DECIMAL_REGEX: /^([0-9]{1,3}[.][0-9]{0,14}|([0-9]{1,3}))$/,
  NUMBER_FIELD:
  " should be a number",
  NUMBER_FIELD_WO_DECIMAL:
    " should be a number without decimal places",
  MAX_ALTITUDE_MSG: "Maximum Altitude should be a number without decimal places",
  NO_OF_KITE: "Number of Kites/Parasails",
  NO_OF_KITE_DIGIT_ONLY: "Number of Kites should be a number without decimal places",
  MAX_HEIGHT_KITE:
    "Maximum Height of Kite/Parasail(s) (feet above mean sea level)",
  MAX_HEIGHT_KITE_DIGIT_ONLY:
  "Maximum Height of Kite/Parasail(s) should be a number without decimal places",
  DIMENSION_LENGTH: "Dimension of Largest Kite/Parasail - Length (m)",
  DIMENSION_LENGTH_AIRSHIP: "Dimension - Length (m)",
  DIMENSION_LENGTH_DIGIT_ONLY:
    "Dimension by breadth should be a number without decimal places",
  DIMENSION_BREADTH: "Dimension of Largest Kite/Parasail - Breadth (m)",
  DIMENSION_BREADTH_AIRSHIP: "Dimension - Breadth (m)",
  DIMENSION_BREADTH_DIGIT_ONLY:
    "Dimension Breadth should be a number without decimal places",
  NO_OF_LANTERNS: "Number of Lanterns",
  MAX_SIZE_LANTERNS: "Maximum Size of Sky Lantern(s) to be released (in cm)",
  MAX_SIZE_LANTERNS_DIGIT_ONLY:
    "Maximum Size of Sky Lantern(s) should be a number without decimal places",
  NO_OF_LANTERNS_DIGIT_ONLY: "Number of Lanterns should be a number without decimal places",
  DANGER_HEIGHT: "Specifications of Lasers / lights used- Danger Height (m)",
  DANGER_HEIGHT_DIGIT_ONLY: "Danger Height should be a number without decimal places",
  DANGER_HEIGHT_INSTALLATION:
    "Specifications of Lasers / Lights used- Danger Height of Installation (m)",
  DANGER_HEIGHT_INSTALLATION_DIGIT_ONLY:
    "Danger Height Installation should be a number without decimal places",
  NO_OF_BALLOONS: "Number of Balloon(s) to be released",
  NO_OF_BALLOONS_DIGIT_ONLY:
    "Maximum Number of Balloons should be a number without decimal places",
  MAX_SIZE_BALLOON: "Maximum size of Balloon(s) to be released (in cm)",
  MAX_SIZE_BALLOON_DIGIT_ONLY:
    "Maximum Size of Balloons should be a number without decimal places",
  DATE_TIME_OF_ACTIVITY: "Date / Time of Activity",
  DATE_TIME_OF_ACTIVITY_NOTE:
    "The Start and End Time for each row of date ranges will indicate the time frame for each day within the date range (e.g. If 20 Dec - 25 Dec, 2pm - 3pm it means the activity will happen from 2pm-3pm everyday from 20 Dec - 25 Dec)",
  DATE_OF_VESSEL_MOVEMENT: "Date of Vessel Movement",
  NAME_OF_VESSEL: "Name of Vessel",
  CALLSIGN: "Callsign",
  HEIGHT_SHIP: "Height / Draft (in metres)",
  HEIGHT_SHIP_DIGIT_ONLY: "Height / Draft should be a number without decimal places",
  PORT_OF_ORIGIN: "Port of Origin",
  POINT_OF_ORIGIN: "Point of Origin",
  FINAL_DESTINATION: "Final Destination",
  EMAIL_ADDRESS_INVALID: "Email Address is invalid",
  SECONDARY_SAFETY_PERSONNEL: "Secondary Safety Personnel",
  PERSONAL_DETAILS: "Personal Details",
  INTENDED_ROUTE: "Intended Route",
  TYPE_OF_FLIGHT: "Type of Aircraft / Platform",
  MAX_HEIGHT_HOISTED_BALLOON:
    "Height of Hoisted Balloon(s) / Blimp(s) above ground level (in feet)",
  MAX_HEIGHT_HOISTED_BALLOON_DIGIT_ONLY:
    "Height of Hoisted Balloon(s) / Blimp(s) should be a number without decimal places",
  AIRCRAFT_TYPE: "Aircraft Type",
  CALLSIGN_OF_AIRCRAFT: "Callsign of Aircraft",
  MINIMUM_OPERATING_ALTITUDE: "Minimum Operating Altitude (in feet)",
  MINIMUM_OPERATING_ALTITUDE_DIGIT_ONLY:
    "Minimum Operating Altitude should be a number without decimal places",
  MAXIMUM_OPERATING_ALTITUDE: "Maximum Operating Altitude (in feet)",
  MAXIMUM_OPERATING_ALTITUDE_DIGIT_ONLY:
    "Maximum Operating Altitude should be a number without decimal places",
  FLYOVERCITYLOWHEIGHT_LABEL:
    "Outside the established helicopter routes, will the aircraft fly over the congested areas of cities, towns or settlements, or over an open-air assembly of persons at a height less than 300m (1000 ft) above the highest obstacle within a radius of 600m from the aircraft?",
  FLYOVERASSEMBLYEVENT_LABEL:
    "Will the aircraft fly over, or within 3,000 feet of any assembly in the open air of more than 1,000 persons assembled for the purpose of witnessing or participating in any organized event?",
  UNIQUE_ID: "ID",
  MACHINERY_SET: "Machinery Set",
  OTHER_MACHINERY_SET: "Other Machinery Set",
  QUANTITY: "Quantity",
  QUANTITY_MANDATORY: "Quantity is mandatory",
  WORKING_HEIGHT: "Working Height, in metres (above ground level)",
  WORKING_HEIGHT_MANDATORY: "Working Height is mandatory",
  QUANTITY_DIGIT_ONLY: "Enter a number without decimal places",
  WORKING_HEIGHT_DIGIT_ONLY: "Enter a number with up to 4 digits and 1 decimal place",
  DATE_START: "Start Date",
  DATE_END: "End Date",
  TIME_START: "Start Time",
  TIME_END: "End Time",
  SELECTED_PAST_DATE_ERR_MSG: "Selected Date cannot be Past Date",
  START_DATE_GREATER_THAN_END_DATE:
    "Start Date must be on or before End Date",
  END_DATE_LESSER_THAN_START_DATE:
    "End Date must be on or after Start Date", //End Date should be equal to or greater than Start Date",
  START_DATE_NOT_GREATER_THAN_THREE_MONTH:
    "Start Date cannot be more than 3 months",
  END_DATE_NOT_GREATER_THAN_TWELVE_MONTH:
    "End Date cannot be more than 12 months from Start Date",
  DECLARE_FORM: "Declaration",
  DECLARE_FORM_MAP: "Location Confirmation",
  DECLARE_FORM_LABEL:
    "I declare that all the information given in this application form is true and correct. I am aware that legal action may be taken against me if I had knowingly provided false information. I agree that in any legal proceedings, I shall not dispute the authenticity or accuracy of any statements, confirmations, records, acknowledgements, information recorded in or produced in this application.",
  DECLARE_FORM_MAP_SELECTION:
    "I understand that the selected area on the map cannot be changed after the application is submitted. If there is a change in location, a new application form should be submitted.", 
    HIGHEST_WORKING_HEIGHT_OF_MACHINERY:
    "Highest Working Height of Machinery, in metres (above ground level)",
  HIGHEST_WORKING_HEIGHT_OF_MACHINERY_DIGITY_ONLY:
    "Highest Working Height a number with up to 4 digits and 1 decimal place",
  MACHINERY_START_DATE: "Machinery Use Start Date",
  MACHINERY_END_DATE: "Machinery Use End Date",
  MACHINERY_AND_EQUIPMENT: "Machinery and Equipment",
  PROJECT_TITLE: "Project Title",
  PROJECT_EXPECTED_COMPLETION_DATE: "Project Expected Completion Date",
  LOCATION_DESCRIPTION: "Location Description",
  LAND_LOT_NO: "Land Lot No.",
  APPROVED_DEVELOPMENT_HEIGHT:
    "Approved Development Height, in metres based on Singapore Height Datum",
  APPROVED_DEVELOPMENT_HEIGHT_DIGIT_ONLY:
    "Approved Development Height a number without decimal places",
  SITE_GROUND_ELEVATION:
    "Site Ground Elevation, in metres based on Singapore Height Datum",
  SITE_GROUND_ELEVATION_DIGIT_ONLY: "Site Ground Elevation should be a number with up to 4 digits and 1 decimal place",
  WHOLE_NUMBER_OR_ONE_DECIMAL: "Value should be a whole number or have no more than 1 decimal place",
  IS_WITHIN_AIRPORT: "Is the work site within airport perimeter fencing?",
  AIRPORT_MACHINERY_DEPLOYED_ADHOC:
    "Is the machinery deployed on an ad hoc basis?",
  AIRPORT_WORK_DURING_RUNWAY_CLOSURE:
    "Is the work carried out during the periods of runway closure?",
  AIRPORT_STOCKPILE_EXISTS:
    "Is there a stockpile or surcharge for the project? If yes, please indicate the following:",
  STOCKPILE_HEIGHT: "Height in metres Above Mean Sea Level",
  STOCKPILE_HEIGHT_DIGIT_ONLY:
    "Height in metres should be a number without decimal places",
  STOCKPILE_LOCATION_LATITUDE: "Location in coordinates Latitude",
  STOCKPILE_LOCATION_LONGITUDE: "Location in coordinates Longitude",
  AIRSIDE_SAFETYMEASURE:
    "Please indicate the safety measures for work within the airside",
  NAME_OF_COMPANY: "Name of Company",
  PROJECT_MANAGER_NAME: "Name of Project Manager",
  CRANE_SUPPLIER: "Crane Supplier / Subcontractor",
  MAIN_CONTRACTOR: "Main Contractor",
  ONSITE_SAFETY_PERSONNEL: "On-site Safety Personnel",
  PROJECT_DETAILS: "Project Details",
  POSTAL_CODE: "Postal Code",
  POSTAL_CODE_DIGIT_ONLY: "Postal Code should be a number without decimal places",
  BLOCK_NO_DIGIT_ONLY: "Block No should be a number",
  STOCKPILE_LOCATION_LATITUDE_DIGIT_ONLY: "Latitude should be a number with up to 3 digits and 14 decimal places",
  STOCKPILE_LOCATION_LONGITUDE_DIGIT_ONLY: "Longitude should be a number with up to 3 digits and 14 decimal places",
  REPORTING_TIME: "Reporting Time (HH:MM)",
  REPORTING_POINT: "Reporting Point",
  ID: "ID",
  CONTACT_NUMBER_PRIMARY_SAFETY: "Contact Number of Primary Safety Personnel",
  CONTACT_NUMBER_SECONDARY_SAFETY:
    "Contact Number of Secondary Safety Personnel",
  EMAIL_ADDRESS_PRIMARY: "Email Address of Primary Safety Personnel",
  EMAIL_ADDRESS_SECONDARY: "Email Address of Secondary Safety Personnel",
  SECONDARY_SAFETY_LABEL_REQUIRED: "Safety Measures Employed",
  SAFETY_MEASURE_HEADER: "Safety Measure",
  SAFETY_MEASURE_INFO: "Designated onsite safety personnel",
  SAFETY_MEASURE_INFO_1:
    "Minimum of 2 personnel, local contact numbers only. Designated onsite safety personnel must remain contactable for the duration of the event.",
  YOUR_PERSONAL_DETAILS: "Your Personal Details",
  REPORTING_ACTIVITY: "Reporting Activity",
  YOUR_APPLICATION_DETAILS: "Your Application Details",
  DEVELOPER: "Developer",
  AIRPORT_SITE_IN_CRITICAL_AREA:
    "Is the work site within the airport critical/sensitive areas?",
  RESTRICTED_DATE_TIME:
    "The proposed activity is not allowed during military operating hours. You may wish to explore alternative days or time",
  MAP_DATA: "Map Data",
  APPLY_FOR_OTHER_SERVICE: "Apply for other SG licences",
  SKYSAFE: "SkySAFE",
  CONTACT_US: "https://www.caas.gov.sg/who-we-are/contact-us",
  FEED_BACK: "https://go.gov.sg/caas-enquiries-feedback-form",
  REPORT_VULNER: "https://tech.gov.sg/report_vulnerability",
  PRIVACY_STATEMENT: "https://www.caas.gov.sg/privacy-statement",
  TERMS_OF_USE: "https://www.caas.gov.sg/terms-of-use",
  GO_BUSINESS: "https://www.gobusiness.gov.sg/",
  CAAS_WEBSITE: "https://www.caas.gov.sg/",
  REACH_LINK: "https://www.reach.gov.sg/",
  USEFUL_LINKS: "https://www.caas.gov.sg/useful-links",
  APPLICATION_TYPE: "Application Type",
  REFERENCE_ID: "Reference Number",
  LOCATION_DETAILS: "Location Selected",
  VERIFY_EMAIL: "Verify",
  EMAIL_ADDRESS_VERIFY: "Verify Your Email",
  VERIFY_EMAIL_OTP: "Verify OTP",
  ENTER_VERIFICATION_CODE: "Enter Verification Code",
  SUBMIT: "Submit",
  DIDINT_RECEIVE_CODE:
    "An email with a 6-digit verification code is sent to you. It will be valid for 30 minutes.",
  TIME_REMAINING:
    "An email with a 6-digit verification code was sent to you. It will be valid for 30 minutes. Resend OTP after:",
  RESEND_OTP: "Resend OTP",
  VERIFICATION_OF_EMAIL: "Email Verification",
  EMAIL_OTP_NOT_VALID:
    "Email OTP Verification is failed, Please provide correct OTP or generate new OTP.",
  SAVING_SCREENSHOT: "Saving Drawing...",
  SAVING_DRAWING: "Save Drawing",
  PAGE_NOT_FOUND: "Oops! We can't find the page.",
  BLIMP_DIMENSION_MAX_ALTITUDE:
    "Maximum Altitude at which Blimp/Airship will be flown (ft)",
  FIREWORK_MAX_DANGER_HEIGHT: "Maximum Danger Height (metres above sea level)",
  FIREWORK_MAX_DANGER_HEIGHT_DIGIT_ONLY:
    "Maximum Danger Height should be a number without decimal places",
  NYA: "Need Your Action",
  PENDING: "Draft",
  PENDING_PAYMENT: "Pending payment",
  INBOX_APPLICATION: "Active Applications",
  PROCESSING: "Processing",
  OUTBOX_PERMITS: "Past Applications",
  REJECTED: "Rejected",
  PAYMENT: "Payment",
  SUPPORTING_DOCUMENTS: "Supporting Documents",
  ADDITIONAL_DOCUMENTS: "Additional Documents",
  RECENT_UPLOADED_FILES: "Drafted Documents",
  DATE: "Date",
  APPLICATION_HISTORY: "Application History",
  REDIRECT_URL: "/login-service/login",
  REDIRECT_SINGPASS_URL: "/mta-login-service/v1/authorize", 
   /*REDIRECT_SINGPASS_URL: "/auth-service/v1/singpass/auth-redirect", */
  REDIRECT_CORPPASS_URL: "/auth-service/v1/corppass/auth-redirect",
  RETRIEVE_MYINFO_URL: "/auth-service/v1/myinfo",
  REDIRECT_PAYMENT_URL: "/permits-service/v1/payment/redirect",
  FULLNAME: "Name",
  EMAILADDRESS: "Email Address",
  ALTERNATIVEEMAIL: "Alternative Email",
  USERNAME: "Username",
  PASSWORD: "Password",
  DOB: "Date Of Birth",
  NAITIONALITY: "Citizenship",
  IDTYPE: "ID Type",
  UNIQUEID: "ID Number",
  FIN: "FIN",
  PASSPORT: "PASSPORT",
  FAXNUMBER: "Fax Number",
  OFFICENUMBER: "Office Number",
  MOBILENUMBER: "Mobile Number",
  IDENTIFICATIONNUMBER: "Identification Number",
  HOMENUMBER: "Home Number",
  ADDRESSTYPE: "Address Type",
  LOCAL: "Local",
  FOREIGN: "Foreign",
  POSTALCODE: "Postal Code.",
  BLOCKNO: "Block/House No.",
  STREETNAME: "Street Name",
  LEVELNO: "Level No.",
  UNITNO: "Unit No.",
  BUILDINGNAME: "Building Name",
  CITY: "City",
  COUNTRY: "Country/Region",
  CITIZENSHIP: "Citizenship",
  PASSPORT_ISSUE_COUNTRY: "Passport issuing Country/Region",
  STATE: "State",
  ADDRESS: "Address",
  RESET_ACCOUNT_PASSOWRD: "Reset Account Password",
  TYPE_OF_ADDRESS: "Type of Address",
  MAIL_TYPE_OF_ADDRESS: "Mailing Address",
  REQUIRED_ERROR_MESSAGE: " is mandatory.",
  REQUIRED_ERROR_MESSAGE_PHONE_NUMBER: " is mandatory and should meet minimum length.",
  IDENTIFICATION_DOCUMENT_TYPE: "Identification Document Type",
  CONFIRMPASSWORD: "Confirm password",
  CLEAR: "Clear",
  PENDING_REGISTRATION:"PENDING_REGISTRATION", 
  PENDING_REGISTRATION_MSG: "User registration is pending.",
  SUBMITTED: "Submitted",
  SUBMITTED_MSG: "Your account is under review. You will receive an email upon approval/any other information is required.",
  MORE_INFO_NEEDED: "MORE_INFO_NEEDED", 
  MORE_INFO_NEEDED_MSG: "Your account is locked/disabled temporarily. Please contact administrator to enable the account.",
  ACTIVE: "ACTIVE", 
  ACTIVE_MSG: "User account is active. Can allow the user to access Dashboard other after login functions.",
  DISABLED: "DISABLED",
  DISABLED_MSG: "Your account is locked/disabled temporarily. Please contact administrator to enable the account.",
  REJECTED_STATUS: "REJECTED",
  REJECTED_MSG: "Your account registration application is not been approved. Please contact administrator for more information.",
  GENERIC_MSG: "Please contact administrator for more information.",
  SALUTATION: "Salutation",
  ADDRESS_TYPE_OF_PREMISES: "Type of Premises",
  ADDRESS_TYPE_OF_PREMISES_OPTION: [
    {
      "label": "Residential",
      "value": "Residential",
      "isdisabled": true,
    },
    {
      "label": "Office",
      "value": "Office",
      "isdisabled": true,
    },
    {
      "label": "Others",
      "value": "Others",
      "isdisabled": true,
    },
  ],
  PRIMARY_CONTACT_MODE: "Primary Contact Mode",
  PRIMARY_CONTACT_MODE_OPTION: [
    {
      "label": "Mobile Number",
      "value": "Mobile",
      "isdisabled": true,
    },
    {
      "label": "Office Tel Number",
      "value": "Office",
      "isdisabled": true,
    },
    {
      "label": "Home Tel Number",
      "value": "Home",
      "isdisabled": true,
    },
    {
      "label": "Fax Number",
      "value": "Fax",
      "isdisabled": true,
    },
  ],
  DESIGNATION: "Designation",
  DESIGNATION_FILTER: [
    {
      "label": "Manging Director",
      "value": "Manging Director",
      "isdisabled": true,
    },
    {
      "label": "Manager",
      "value": "Manager",
      "isdisabled": true,
    },
    {
      "label": "Director",
      "value": "Director",
      "isdisabled": true,
    },
    {
      "label": "Officer",
      "value": "Officer",
      "isdisabled": true,
    },
    {
      "label": "Secretary",
      "value": "Secretary",
      "isdisabled": true,
    },
    {
      "label": "Agent",
      "value": "Agent",
      "isdisabled": true,
    },
    {
      "label": "Auditor",
      "value": "Auditor",
      "isdisabled": true,
    },
    {
      "label": "General Manager",
      "value": "General Manager",
      "isdisabled": true,
    },
    {
      "label": "Others",
      "value": "Others",
      "isdisabled": true,
    },
    {
      "label": "Shareholder",
      "value": "Shareholder",
      "isdisabled": true,
    },
    {
      "label": "Director and Shareholder",
      "value": "Director and Shareholder",
      "isdisabled": true,
    },

  ],
  SAME_AS_LOCAL_ADDRESS: "Same as local address",
  SAME_AS_FOREIGN_ADDRESS: "Same as foreign address",
  ADDRESS_1: "Address Line 1",
  ADDRESS_2: "Address Line 2",
  ADDRESS_3: "Address Line 3",
  MAIL_ADDRESS: "Mailing Address",
  BROKEN_MSG:"We apologise for the inconvenience. Please try again later. If the problem persists, please contact us at CAAS_ATS_ANSP@caas.gov.sg",
  OTHER_FOREIGNER_TYPE_OF_PREMISES_ADDRESS: "Other foreigner type of Premises",
  MAILING_ADDRESS_LOCAL_TYPE_OF_PREMISE_OTHERS: "Mailing local address of other premises",
  USER_SUCCESS_MSG: "User details have been submitted successfully",
  SUCCESS: "Success",
  OTHERS: "Others",
  EXIST_EMAILADDRESS: "The Email address is already registered. Please try with different one.",
  NRIC_INVALID_ERROR: "NRIC or FIN is invalid"
};

export const Country = [
{
  "label": "Albania",
  "isdisabled": true,
  "value": "AL"
},
{
  "label": "Åland Islands",
  "isdisabled": true,
  "value": "AX"
},
{
  "label": "Algeria",
  "isdisabled": true,
  "value": "DZ"
},
{
  "label": "American Samoa",
  "isdisabled": true,
  "value": "AS"
},
{
  "label": "Andorra",
  "isdisabled": true,
  "value": "AD"
},
{
  "label": "Angola",
  "isdisabled": true,
  "value": "AO"
},
{
  "label": "Anguilla",
  "isdisabled": true,
  "value": "AI"
},
{
  "label": "Antarctica",
  "isdisabled": true,
  "value": "AQ"
},
{
  "label": "Antigua and Barbuda",
  "isdisabled": true,
  "value": "AG"
},
{
  "label": "Argentina",
  "isdisabled": true,
  "value": "AR"
},
{
  "label": "Armenia",
  "isdisabled": true,
  "value": "AM"
},
{
  "label": "Aruba",
  "isdisabled": true,
  "value": "AW"
},
{
  "label": "Australia",
  "isdisabled": true,
  "value": "AU"
},
{
  "label": "Austria",
  "isdisabled": true,
  "value": "AT"
},
{
  "label": "Azerbaijan",
  "isdisabled": true,
  "value": "AZ"
},
{
  "label": "Bahamas (the)",
  "isdisabled": true,
  "value": "BS"
},
{
  "label": "Bahrain",
  "isdisabled": true,
  "value": "BH"
},
{
  "label": "Bangladesh",
  "isdisabled": true,
  "value": "BD"
},
{
  "label": "Barbados",
  "isdisabled": true,
  "value": "BB"
},
{
  "label": "Belarus",
  "isdisabled": true,
  "value": "BY"
},
{
  "label": "Belgium",
  "isdisabled": true,
  "value": "BE"
},
{
  "label": "Belize",
  "isdisabled": true,
  "value": "BZ"
},
{
  "label": "Benin",
  "isdisabled": true,
  "value": "BJ"
},
{
  "label": "Bermuda",
  "isdisabled": true,
  "value": "BM"
},
{
  "label": "Bhutan",
  "isdisabled": true,
  "value": "BT"
},
{
  "label": "Bolivia (Plurinational State of)",
  "isdisabled": true,
  "value": "BO"
},
{
  "label": "Bonaire, Sint Eustatius and Saba",
  "isdisabled": true,
  "value": "BQ"
},
{
  "label": "Bosnia and Herzegovina",
  "isdisabled": true,
  "value": "BA"
},
{
  "label": "Botswana",
  "isdisabled": true,
  "value": "BW"
},
{
  "label": "Bouvet Island",
  "isdisabled": true,
  "value": "BV"
},
{
  "label": "Brazil",
  "isdisabled": true,
  "value": "BR"
},
{
  "label": "British Indian Ocean Territory (the)",
  "isdisabled": true,
  "value": "IO"
},
{
  "label": "Brunei Darussalam",
  "isdisabled": true,
  "value": "BN"
},
{
  "label": "Bulgaria",
  "isdisabled": true,
  "value": "BG"
},
{
  "label": "Burkina Faso",
  "isdisabled": true,
  "value": "BF"
},
{
  "label": "Burundi",
  "isdisabled": true,
  "value": "BI"
},
{
  "label": "Cabo Verde",
  "isdisabled": true,
  "value": "CV"
},
{
  "label": "Cambodia",
  "isdisabled": true,
  "value": "KH"
},
{
  "label": "Cameroon",
  "isdisabled": true,
  "value": "CM"
},
{
  "label": "Canada",
  "isdisabled": true,
  "value": "CA"
},
{
  "label": "Cayman Islands (the)",
  "isdisabled": true,
  "value": "KY"
},
{
  "label": "Central African Republic (the)",
  "isdisabled": true,
  "value": "CF"
},
{
  "label": "Chad",
  "isdisabled": true,
  "value": "TD"
},
{
  "label": "Chile",
  "isdisabled": true,
  "value": "CL"
},
{
  "label": "China",
  "isdisabled": true,
  "value": "CN"
},
{
  "label": "Christmas Island",
  "isdisabled": true,
  "value": "CX"
},
{
  "label": "Cocos (Keeling) Islands (the)",
  "isdisabled": true,
  "value": "CC"
},
{
  "label": "Colombia",
  "isdisabled": true,
  "value": "CO"
},
{
  "label": "Comoros (the)",
  "isdisabled": true,
  "value": "KM"
},
{
  "label": "Congo (the Democratic Republic of the)",
  "isdisabled": true,
  "value": "CD"
},
{
  "label": "Congo (the)",
  "isdisabled": true,
  "value": "CG"
},
{
  "label": "Cook Islands (the)",
  "isdisabled": true,
  "value": "CK"
},
{
  "label": "Costa Rica",
  "isdisabled": true,
  "value": "CR"
},
{
  "label": "Croatia",
  "isdisabled": true,
  "value": "HR"
},
{
  "label": "Cuba",
  "isdisabled": true,
  "value": "CU"
},
{
  "label": "Curaçao",
  "isdisabled": true,
  "value": "CW"
},
{
  "label": "Cyprus",
  "isdisabled": true,
  "value": "CY"
},
{
  "label": "Czechia",
  "isdisabled": true,
  "value": "CZ"
},
{
  "label": "Côte d'Ivoire",
  "isdisabled": true,
  "value": "CI"
},
{
  "label": "Denmark",
  "isdisabled": true,
  "value": "DK"
},
{
  "label": "Djibouti",
  "isdisabled": true,
  "value": "DJ"
},
{
  "label": "Dominica",
  "isdisabled": true,
  "value": "DM"
},
{
  "label": "Dominican Republic (the)",
  "isdisabled": true,
  "value": "DO"
},
{
  "label": "Ecuador",
  "isdisabled": true,
  "value": "EC"
},
{
  "label": "Egypt",
  "isdisabled": true,
  "value": "EG"
},
{
  "label": "El Salvador",
  "isdisabled": true,
  "value": "SV"
},
{
  "label": "Equatorial Guinea",
  "isdisabled": true,
  "value": "GQ"
},
{
  "label": "Eritrea",
  "isdisabled": true,
  "value": "ER"
},
{
  "label": "Estonia",
  "isdisabled": true,
  "value": "EE"
},
{
  "label": "Eswatini",
  "isdisabled": true,
  "value": "SZ"
},
{
  "label": "Ethiopia",
  "isdisabled": true,
  "value": "ET"
},
{
  "label": "Falkland Islands (the) [Malvinas]",
  "isdisabled": true,
  "value": "FK"
},
{
  "label": "Faroe Islands (the)",
  "isdisabled": true,
  "value": "FO"
},
{
  "label": "Fiji",
  "isdisabled": true,
  "value": "FJ"
},
{
  "label": "Finland",
  "isdisabled": true,
  "value": "FI"
},
{
  "label": "France",
  "isdisabled": true,
  "value": "FR"
},
{
  "label": "French Guiana",
  "isdisabled": true,
  "value": "GF"
},
{
  "label": "French Polynesia",
  "isdisabled": true,
  "value": "PF"
},
{
  "label": "French Southern Territories (the)",
  "isdisabled": true,
  "value": "TF"
},
{
  "label": "Gabon",
  "isdisabled": true,
  "value": "GA"
},
{
  "label": "Gambia (the)",
  "isdisabled": true,
  "value": "GM"
},
{
  "label": "Georgia",
  "isdisabled": true,
  "value": "GE"
},
{
  "label": "Germany",
  "isdisabled": true,
  "value": "DE"
},
{
  "label": "Ghana",
  "isdisabled": true,
  "value": "GH"
},
{
  "label": "Gibraltar",
  "isdisabled": true,
  "value": "GI"
},
{
  "label": "Greece",
  "isdisabled": true,
  "value": "GR"
},
{
  "label": "Greenland",
  "isdisabled": true,
  "value": "GL"
},
{
  "label": "Grenada",
  "isdisabled": true,
  "value": "GD"
},
{
  "label": "Guadeloupe",
  "isdisabled": true,
  "value": "GP"
},
{
  "label": "Guam",
  "isdisabled": true,
  "value": "GU"
},
{
  "label": "Guatemala",
  "isdisabled": true,
  "value": "GT"
},
{
  "label": "Guernsey",
  "isdisabled": true,
  "value": "GG"
},
{
  "label": "Guinea",
  "isdisabled": true,
  "value": "GN"
},
{
  "label": "Guinea-Bissau",
  "isdisabled": true,
  "value": "GW"
},
{
  "label": "Guyana",
  "isdisabled": true,
  "value": "GY"
},
{
  "label": "Haiti",
  "isdisabled": true,
  "value": "HT"
},
{
  "label": "Heard Island and McDonald Islands",
  "isdisabled": true,
  "value": "HM"
},
{
  "label": "Holy See (the)",
  "isdisabled": true,
  "value": "VA"
},
{
  "label": "Honduras",
  "isdisabled": true,
  "value": "HN"
},
{
  "label": "Hong Kong",
  "isdisabled": true,
  "value": "HK"
},
{
  "label": "Hungary",
  "isdisabled": true,
  "value": "HU"
},
{
  "label": "Iceland",
  "isdisabled": true,
  "value": "IS"
},
{
  "label": "India",
  "isdisabled": true,
  "value": "IN"
},
{
  "label": "Indonesia",
  "isdisabled": true,
  "value": "ID"
},
{
  "label": "Iran (Islamic Republic of)",
  "isdisabled": true,
  "value": "IR"
},
{
  "label": "Iraq",
  "isdisabled": true,
  "value": "IQ"
},
{
  "label": "Ireland",
  "isdisabled": true,
  "value": "IE"
},
{
  "label": "Isle of Man",
  "isdisabled": true,
  "value": "IM"
},
{
  "label": "Israel",
  "isdisabled": true,
  "value": "IL"
},
{
  "label": "Italy",
  "isdisabled": true,
  "value": "IT"
},
{
  "label": "Jamaica",
  "isdisabled": true,
  "value": "JM"
},
{
  "label": "Japan",
  "isdisabled": true,
  "value": "JP"
},
{
  "label": "Jersey",
  "isdisabled": true,
  "value": "JE"
},
{
  "label": "Jordan",
  "isdisabled": true,
  "value": "JO"
},
{
  "label": "Kazakhstan",
  "isdisabled": true,
  "value": "KZ"
},
{
  "label": "Kenya",
  "isdisabled": true,
  "value": "KE"
},
{
  "label": "Kiribati",
  "isdisabled": true,
  "value": "KI"
},
{
  "label": "Korea (the Democratic People's Republic of)",
  "isdisabled": true,
  "value": "KP"
},
{
  "label": "Korea (the Republic of)",
  "isdisabled": true,
  "value": "KR"
},
{
  "label": "Kuwait",
  "isdisabled": true,
  "value": "KW"
},
{
  "label": "Kyrgyzstan",
  "isdisabled": true,
  "value": "KG"
},
{
  "label": "Lao People's Democratic Republic (the)",
  "isdisabled": true,
  "value": "LA"
},
{
  "label": "Latvia",
  "isdisabled": true,
  "value": "LV"
},
{
  "label": "Lebanon",
  "isdisabled": true,
  "value": "LB"
},
{
  "label": "Lesotho",
  "isdisabled": true,
  "value": "LS"
},
{
  "label": "Liberia",
  "isdisabled": true,
  "value": "LR"
},
{
  "label": "Libya",
  "isdisabled": true,
  "value": "LY"
},
{
  "label": "Liechtenstein",
  "isdisabled": true,
  "value": "LI"
},
{
  "label": "Lithuania",
  "isdisabled": true,
  "value": "LT"
},
{
  "label": "Luxembourg",
  "isdisabled": true,
  "value": "LU"
},
{
  "label": "Macao",
  "isdisabled": true,
  "value": "MO"
},
{
  "label": "Madagascar",
  "isdisabled": true,
  "value": "MG"
},
{
  "label": "Malawi",
  "isdisabled": true,
  "value": "MW"
},
{
  "label": "Malaysia",
  "isdisabled": true,
  "value": "MY"
},
{
  "label": "Maldives",
  "isdisabled": true,
  "value": "MV"
},
{
  "label": "Mali",
  "isdisabled": true,
  "value": "ML"
},
{
  "label": "Malta",
  "isdisabled": true,
  "value": "MT"
},
{
  "label": "Marshall Islands (the)",
  "isdisabled": true,
  "value": "MH"
},
{
  "label": "Martinique",
  "isdisabled": true,
  "value": "MQ"
},
{
  "label": "Mauritania",
  "isdisabled": true,
  "value": "MR"
},
{
  "label": "Mauritius",
  "isdisabled": true,
  "value": "MU"
},
{
  "label": "Mayotte",
  "isdisabled": true,
  "value": "YT"
},
{
  "label": "Mexico",
  "isdisabled": true,
  "value": "MX"
},
{
  "label": "Micronesia (Federated States of)",
  "isdisabled": true,
  "value": "FM"
},
{
  "label": "Moldova (the Republic of)",
  "isdisabled": true,
  "value": "MD"
},
{
  "label": "Monaco",
  "isdisabled": true,
  "value": "MC"
},
{
  "label": "Mongolia",
  "isdisabled": true,
  "value": "MN"
},
{
  "label": "Montenegro",
  "isdisabled": true,
  "value": "ME"
},
{
  "label": "Montserrat",
  "isdisabled": true,
  "value": "MS"
},
{
  "label": "Morocco",
  "isdisabled": true,
  "value": "MA"
},
{
  "label": "Mozambique",
  "isdisabled": true,
  "value": "MZ"
},
{
  "label": "Myanmar",
  "isdisabled": true,
  "value": "MM"
},
{
  "label": "Namibia",
  "isdisabled": true,
  "value": "NA"
},
{
  "label": "Nauru",
  "isdisabled": true,
  "value": "NR"
},
{
  "label": "Nepal",
  "isdisabled": true,
  "value": "NP"
},
{
  "label": "Netherlands (the)",
  "isdisabled": true,
  "value": "NL"
},
{
  "label": "New Caledonia",
  "isdisabled": true,
  "value": "NC"
},
{
  "label": "New Zealand",
  "isdisabled": true,
  "value": "NZ"
},
{
  "label": "Nicaragua",
  "isdisabled": true,
  "value": "NI"
},
{
  "label": "Niger (the)",
  "isdisabled": true,
  "value": "NE"
},
{
  "label": "Nigeria",
  "isdisabled": true,
  "value": "NG"
},
{
  "label": "Niue",
  "isdisabled": true,
  "value": "NU"
},
{
  "label": "Norfolk Island",
  "isdisabled": true,
  "value": "NF"
},
{
  "label": "Northern Mariana Islands (the)",
  "isdisabled": true,
  "value": "MP"
},
{
  "label": "Norway",
  "isdisabled": true,
  "value": "NO"
},
{
  "label": "Oman",
  "isdisabled": true,
  "value": "OM"
},
{
  "label": "Pakistan",
  "isdisabled": true,
  "value": "PK"
},
{
  "label": "Palau",
  "isdisabled": true,
  "value": "PW"
},
{
  "label": "Palestine, State of",
  "isdisabled": true,
  "value": "PS"
},
{
  "label": "Panama",
  "isdisabled": true,
  "value": "PA"
},
{
  "label": "Papua New Guinea",
  "isdisabled": true,
  "value": "PG"
},
{
  "label": "Paraguay",
  "isdisabled": true,
  "value": "PY"
},
{
  "label": "Peru",
  "isdisabled": true,
  "value": "PE"
},
{
  "label": "Philippines (the)",
  "isdisabled": true,
  "value": "PH"
},
{
  "label": "Pitcairn",
  "isdisabled": true,
  "value": "PN"
},
{
  "label": "Poland",
  "isdisabled": true,
  "value": "PL"
},
{
  "label": "Portugal",
  "isdisabled": true,
  "value": "PT"
},
{
  "label": "Puerto Rico",
  "isdisabled": true,
  "value": "PR"
},
{
  "label": "Qatar",
  "isdisabled": true,
  "value": "QA"
},
{
  "label": "Republic of North Macedonia",
  "isdisabled": true,
  "value": "MK"
},
{
  "label": "Romania",
  "isdisabled": true,
  "value": "RO"
},
{
  "label": "Russian Federation (the)",
  "isdisabled": true,
  "value": "RU"
},
{
  "label": "Rwanda",
  "isdisabled": true,
  "value": "RW"
},
{
  "label": "Réunion",
  "isdisabled": true,
  "value": "RE"
},
{
  "label": "Saint Barthélemy",
  "isdisabled": true,
  "value": "BL"
},
{
  "label": "Saint Helena, Ascension and Tristan da Cunha",
  "isdisabled": true,
  "value": "SH"
},
{
  "label": "Saint Kitts and Nevis",
  "isdisabled": true,
  "value": "KN"
},
{
  "label": "Saint Lucia",
  "isdisabled": true,
  "value": "LC"
},
{
  "label": "Saint Martin (French part)",
  "isdisabled": true,
  "value": "MF"
},
{
  "label": "Saint Pierre and Miquelon",
  "isdisabled": true,
  "value": "PM"
},
{
  "label": "Saint Vincent and the Grenadines",
  "isdisabled": true,
  "value": "VC"
},
{
  "label": "Samoa",
  "isdisabled": true,
  "value": "WS"
},
{
  "label": "San Marino",
  "isdisabled": true,
  "value": "SM"
},
{
  "label": "Sao Tome and Principe",
  "isdisabled": true,
  "value": "ST"
},
{
  "label": "Saudi Arabia",
  "isdisabled": true,
  "value": "SA"
},
{
  "label": "Senegal",
  "isdisabled": true,
  "value": "SN"
},
{
  "label": "Serbia",
  "isdisabled": true,
  "value": "RS"
},
{
  "label": "Seychelles",
  "isdisabled": true,
  "value": "SC"
},
{
  "label": "Sierra Leone",
  "isdisabled": true,
  "value": "SL"
},
{
  "label": "Singapore",
  "isdisabled": true,
  "value": "SG"
},
{
  "label": "Sint Maarten (Dutch part)",
  "isdisabled": true,
  "value": "SX"
},
{
  "label": "Slovakia",
  "isdisabled": true,
  "value": "SK"
},
{
  "label": "Slovenia",
  "isdisabled": true,
  "value": "SI"
},
{
  "label": "Solomon Islands",
  "isdisabled": true,
  "value": "SB"
},
{
  "label": "Somalia",
  "isdisabled": true,
  "value": "SO"
},
{
  "label": "South Africa",
  "isdisabled": true,
  "value": "ZA"
},
{
  "label": "South Georgia and the South Sandwich Islands",
  "isdisabled": true,
  "value": "GS"
},
{
  "label": "South Sudan",
  "isdisabled": true,
  "value": "SS"
},
{
  "label": "Spain",
  "isdisabled": true,
  "value": "ES"
},
{
  "label": "Sri Lanka",
  "isdisabled": true,
  "value": "LK"
},
{
  "label": "Sudan (the)",
  "isdisabled": true,
  "value": "SD"
},
{
  "label": "Suriname",
  "isdisabled": true,
  "value": "SR"
},
{
  "label": "Svalbard and Jan Mayen",
  "isdisabled": true,
  "value": "SJ"
},
{
  "label": "Sweden",
  "isdisabled": true,
  "value": "SE"
},
{
  "label": "Switzerland",
  "isdisabled": true,
  "value": "CH"
},
{
  "label": "Syrian Arab Republic",
  "isdisabled": true,
  "value": "SY"
},
{
  "label": "Taiwan (Province of China)",
  "isdisabled": true,
  "value": "TW"
},
{
  "label": "Tajikistan",
  "isdisabled": true,
  "value": "TJ"
},
{
  "label": "Tanzania, United Republic of",
  "isdisabled": true,
  "value": "TZ"
},
{
  "label": "Thailand",
  "isdisabled": true,
  "value": "TH"
},
{
  "label": "Timor-Leste",
  "isdisabled": true,
  "value": "TL"
},
{
  "label": "Togo",
  "isdisabled": true,
  "value": "TG"
},
{
  "label": "Tokelau",
  "isdisabled": true,
  "value": "TK"
},
{
  "label": "Tonga",
  "isdisabled": true,
  "value": "TO"
},
{
  "label": "Trinidad and Tobago",
  "isdisabled": true,
  "value": "TT"
},
{
  "label": "Tunisia",
  "isdisabled": true,
  "value": "TN"
},
{
  "label": "Turkey",
  "isdisabled": true,
  "value": "TR"
},
{
  "label": "Turkmenistan",
  "isdisabled": true,
  "value": "TM"
},
{
  "label": "Turks and Caicos Islands (the)",
  "isdisabled": true,
  "value": "TC"
},
{
  "label": "Tuvalu",
  "isdisabled": true,
  "value": "TV"
},
{
  "label": "Uganda",
  "isdisabled": true,
  "value": "UG"
},
{
  "label": "Ukraine",
  "isdisabled": true,
  "value": "UA"
},
{
  "label": "United Arab Emirates (the)",
  "isdisabled": true,
  "value": "AE"
},
{
  "label": "United Kingdom of Great Britain and Northern Ireland (the)",
  "isdisabled": true,
  "value": "GB"
},
{
  "label": "United States Minor Outlying Islands (the)",
  "isdisabled": true,
  "value": "UM"
},
{
  "label": "United States of America (the)",
  "isdisabled": true,
  "value": "US"
},
{
  "label": "Uruguay",
  "isdisabled": true,
  "value": "UY"
},
{
  "label": "Uzbekistan",
  "isdisabled": true,
  "value": "UZ"
},
{
  "label": "Vanuatu",
  "isdisabled": true,
  "value": "VU"
},
{
  "label": "Venezuela (Bolivarian Republic of)",
  "isdisabled": true,
  "value": "VE"
},
{
  "label": "Viet Nam",
  "isdisabled": true,
  "value": "VN"
},
{
  "label": "Virgin Islands (British)",
  "isdisabled": true,
  "value": "VG"
},
{
  "label": "Virgin Islands (U.S.)",
  "isdisabled": true,
  "value": "VI"
},
{
  "label": "Wallis and Futuna",
  "isdisabled": true,
  "value": "WF"
},
{
  "label": "Western Sahara",
  "isdisabled": true,
  "value": "EH"
},
{
  "label": "Yemen",
  "isdisabled": true,
  "value": "YE"
},
{
  "label": "Zambia",
  "isdisabled": true,
  "value": "ZM"
},
{
  "label": "Zimbabwe",
  "isdisabled": true,
  "value": "ZW"
}
];

export default Constant;
